exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mdx-slug-js-content-file-path-opt-build-repo-content-posts-hello-world-md": () => import("./../../../src/pages/blog/{mdx.slug}.js?__contentFilePath=/opt/build/repo/content/posts/hello-world.md" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js-content-file-path-opt-build-repo-content-posts-hello-world-md" */),
  "component---src-pages-blog-mdx-slug-js-content-file-path-opt-build-repo-content-posts-seo-google-devs-recomendations-md": () => import("./../../../src/pages/blog/{mdx.slug}.js?__contentFilePath=/opt/build/repo/content/posts/SEO-Google-Devs-Recomendations.md" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js-content-file-path-opt-build-repo-content-posts-seo-google-devs-recomendations-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

